document.addEventListener('DOMContentLoaded', () => {
    const body = document.querySelector('body');

    // Ensure we're on the correct controller and action
    if (body?.dataset.controller === 'pages' && body.dataset.action === 'index') {
        const modalWrapper = document.getElementById('decision-modal-wrapper');
        const modal = document.querySelector('.modal-box'); // Updated modal box
        const stinterButton = document.getElementById('btn-stinter');
        const notStinterButton = document.getElementById('btn-not-stinter');

        // Helper to manage cookies
        const setCookie = (name, value, days) => {
            const date = new Date();
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
            document.cookie = `${name}=${value}; expires=${date.toUTCString()}; path=/`;
        };

        const getCookie = (name) => {
            const value = `; ${document.cookie}`;
            const parts = value.split(`; ${name}=`);
            return parts.length === 2 ? parts.pop().split(';').shift() : null;
        };

        // Extract locale from the URL or body data attribute
        const locale = body.dataset.locale || window.location.pathname.split('/')[1] || 'en-gb';

        // Check for the cookie on page load
        if (!getCookie('is_stinter')) {
            modalWrapper?.classList.remove('hidden'); // Show the modal wrapper
            modalWrapper.style.display = 'flex'; // Ensure flex layout for modal
        }

        // Set the cookie and redirect when a button is clicked
        stinterButton?.addEventListener('click', () => {
            setCookie('is_stinter', 'true', 30);
            window.location.href = `/${locale}/for-stinters`;
        });

        notStinterButton?.addEventListener('click', () => {
            setCookie('is_stinter', 'false', 30);
            window.location.href = `/${locale}/for-partners`;
        });
    }
});
